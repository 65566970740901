@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=New+Tegomin&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-item-background: #000000;
    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-border-color: #222222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-item-background: #1e1e1e;
    --ion-toolbar-background: #1f1f1f;
    --ion-tab-bar-background: #1f1f1f;
    --ion-card-background: #1e1e1e;
  }
}

html {
  /* For more information on dynamic font scaling, visit the documentation: 
  https://ionicframework.com/docs/layout/dynamic-font-scaling */
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}

* {
  font-family: "Nunito Sans", sans-serif !important;
}

/* Ionic Accordian Style */
:root {
  --ion-color-rose: #22a45d;
  --ion-color-rose-rgb: 247, 247, 247;
  --ion-color-rose-contrast: #ffff;
  --ion-color-rose-contrast-rgb: 0, 0, 0;
  --ion-color-rose-shade: #f7f7f77d;
  --ion-color-rose-tint: #f7f7f77d;
  --rmdp-today-green: #96e2b7;
  --rmdp-primary-green: #22a45d;
}

.ion-color-rose {
  --ion-color-base: var(--ion-color-rose);
  --ion-color-base-rgb: var(--ion-color-rose-rgb);
  --ion-color-contrast: var(--ion-color-rose-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rose-contrast-rgb);
  --ion-color-shade: var(--ion-color-rose-shade);
  --ion-color-tint: var(--ion-color-rose-tint);
}

div[slot="content"] {
  background: rgba(var(--ion-color-rose-rgb), 0.66);
  color: #7e7e7e;
}

ion-popover {
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
}

@media (prefers-color-scheme: dark) {
  .ant-rate .ant-rate-star-second {
    color: rgba(208, 195, 195, 0.3);
  }
}

/* For mobile phones */
@media only screen and (max-width: 767px) {
  .list-home {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .HomeTab_box .box .btn1 {
    padding-right: 15px;
    padding-left: 10px;
    margin-right: 1px !important;
  }

  .HomeTab_box .box .btn2 {
    padding-right: 10px;
    padding-left: 20px;
    margin-left: -14px !important;
    margin-right: -1px !important;
  }

  .HomeTab_box .box .btn3 {
    padding-right: 15px;
    padding-left: 25px;
  }
}

/* For iPads */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .list-home {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .HomeTab_box .box .btn1 {
    margin-right: -16px !important;
  }

  .HomeTab_box .box .btn2 {
    margin-left: unset !important;
    margin-right: -3px !important;
  }
}

h1 {
  margin-top: 0.5em;
  font-weight: 500;
  font-size: 2em;
}

h2 {
  margin-top: 0;
  /* margin-top: 1em; */
  font-weight: 300;
  font-size: 1.75em;
}

h3 {
  @apply font-semibold;
}

.checkbox {
  width: auto;
  display: inline;
  width: 20px;
  height: 20px;
  margin: 0;
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.back-chevron {
  position: absolute;
  left: 8px;
  top: 8px;
  font-size: 18px;
  cursor: pointer;
  background: rgb(93 66 66 / 72%);
  backdrop-filter: blur(7.5px);
  padding: 7px 6px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px -5px black;
}

.fav-Icon {
  border: "2px solid #F04A3F";
}

.fave-heart {
  color: #f04a3f;
  position: absolute;
  top: 15px;
  right: 70px;
  font-size: 18px;
  cursor: pointer;
  background: #f03f3f29;
  padding: 10px 9px;
  backdrop-filter: blur(7.5px);
  border-radius: 20px;
}

.info-circle {
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 18px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.5px);
  padding: 10px 9px;
  border-radius: 20px;
}

.header-icon {
  font-size: 22px;
  cursor: pointer;
}

.header-right-icon {
  position: absolute;
  top: 45px;
  right: 20px;
}

.screen-trash {
  position: absolute;
  right: 40px;
  top: 45px;
  font-size: 22px;
  cursor: pointer;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 4;
  background: white;
}

.float-create-button,
.float-cart-button {
  width: 64px;
  height: 64px;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 84px;
  z-index: 3;
}

.float-create-button {
  right: 24px;
}

.float-cart-button {
  left: 24px;
}

.float-create-button > a,
.float-cart-button > a {
  border-radius: 32px;
  padding: 0;
  margin: 0;
  font-size: 32px;
  line-height: 60px;
}

.float-cart-button > a > svg {
  position: relative;
  top: 17px;
}

.schedule-row {
  @apply py-2 pl-2;
  cursor: pointer;
}

.icon-prefixed {
  display: inline-block;
  @apply ml-2;
}

.icon {
  color: #b5bbc9;
  cursor: pointer;
}

.activeicon {
  color: #22a45d;
}

.fave-hearts {
  color: #9022a4;
}

.disabledicon {
  @apply text-gray-400;
}

.fill-or-crate-button.disable {
  cursor: auto;
}

.tinybold {
  @apply text-xs text-gray-400 font-semibold;
}

.mediumbold {
  @apply font-semibold;
}

.subtext {
  @apply text-gray-400;
}

input,
select,
.input {
  @apply block w-full bg-slate-200 rounded-lg p-2 my-1;
}

input.day-check-time {
  width: auto;
  display: inline-block;
}

label {
  @apply text-sm;
}

.cover {
  @apply absolute top-0 left-0 right-0 bottom-0 backdrop-brightness-50;
}

.lst-section-heading {
  @apply tinybold border-b px-4 pt-4 pb-2;
}

.lst-item {
  @apply px-4 py-2 border-b;
}

.active {
  @apply bg-primary text-primary;
}

.active .subtext {
  @apply text-primary;
}

.control-h {
  height: 24px;
  border-radius: 12px;
}

a {
  @apply text-asprimary cursor-pointer;
}

.control-circle {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: white;
  display: inline-block;
  @apply border-gray-300 border;
}

.control-lh {
  height: 48px;
  border-radius: 24px;
}

.control-bg {
  @apply bg-gray-200;
}

.slotted-select {
  padding: 4px;
  border-radius: 4px;
  height: 24px;
}

.switch {
  width: 48px;
  @apply inline-block;
}

.restrictions-table {
  @apply table-auto w-full text-center;
}

.flex-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.restrictions-table td {
  @apply p-2 cursor-pointer;
}

.pill {
  /* white-space: nowrap; */
  @apply control-h control-bg py-2 px-4 mx-2 cursor-pointer;
  position: relative;
  top: 5px;
  line-height: 40px;
}

.pill.active {
  @apply bg-primary text-primary;
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
}

.hidden-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.error {
  @apply text-red-700;
}

.screen {
  display: flex;
  flex-direction: column;
  /* rm footer height */
  height: calc(100% - 69px);
}

.search-result {
  min-height: 110px;
  @apply flex py-2 border-b;
  cursor: pointer;
}

.fill-or-crate-button {
  /* width: 120px;
  max-width: 250px; */
  min-width: 120px;
  padding: 15px 10px;
  cursor: pointer;
  border: 1px solid #868686;
  display: inline-block;
  border-radius: 20px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 1rem;
  place-self: center;
}

.create-button {
  cursor: pointer;
}

.realtime-summary-section {
  border: 1px solid grey;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 10px;
}

.realtime-summary-section > svg {
  /* position: absolute; */
  float: right;
  right: 20px;
  top: 20px;
}

.realtime-summary-section > h2,
.realtime-summary-section > svg {
  cursor: pointer;
}

.fill-or-create-buttons {
  padding-top: 120px;
}

.meal-card {
  position: relative;
}

.meal-card > svg {
  position: absolute;
  top: 0px;
  left: calc(100% - 20px);
  cursor: pointer;
  z-index: 2;
}

.schedule-slot-list {
  max-height: 400px;
  overflow-y: auto;
}

.schedule-slot-list > li {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid grey;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.pick-slots-overlay .schedule-slot-list {
  margin-top: 20px;
}

.pick-slots-overlay .divider {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pick-slots-overlay-confirm {
  margin-top: 20px;
}

.fill-button {
  border: 1px solid grey;
  display: inline-block;
  padding: 5px;
  width: 75px;
  /* margin-left: auto; */
  margin-left: 10px;
  text-align: center;
}

.schedule-slot-date {
  flex-grow: 1;
}

.clear {
  clear: both;
}

.fill-check > input {
  margin-right: 20px;
  width: 25px;
  height: 25px;
  margin-top: 0;
  margin-bottom: 0;
}

.pull-right {
  float: right;
}

.display-none {
  display: none;
}

.meal-assignment-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  display: grid;
  align-items: center;
  grid-template-rows: auto;
  grid-template-columns: 33% 33% 33%;
  gap: 10px;
  justify-content: center;
}

.slot-pill {
  padding: 8px;
  background: #20a45d;
  display: inline-block;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  text-align: center;
}

.top-right {
  position: absolute;
  top: 20px;
  right: 20px;
}

.faded-glass {
  background: rgba(0, 0, 0, 0.2);
}

.swiped {
  transform: translate(-40px);
}

.swiped-in {
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: 0px;
}

.fRSOoJ {
  height: 230px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-evenly !important;
}

/* -----------------------------------------------------Modal Date Time Picker--------------------------------------------------------------------------- */

.picker-window {
  position: absolute;
  border-top: 1px solid #d2d1d6;
  border-bottom: 1px solid #d2d1d6;
  height: 1.6em;
  left: 0;
  top: 55%;
  transform: translateY(-65%);
  width: 100%;
  pointer-events: none;
}

.triangle {
  content: "";
  background: white;
  height: 20px;
  width: 20px;
  position: absolute;
  display: block;
  top: -55px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.picker {
  display: grid;
  grid-auto-flow: column;
  position: relative;
  box-sizing: border-box;
}

.picker::before,
.picker::after {
  content: " ";
  position: absolute;
  top: -1;
  background: linear-gradient(hsl(0deg 0% 100%), 65%, rgba(229, 229, 229, 0));
  width: 100%;
  height: calc(62% - 3.7ex);
  pointer-events: none;
}

.picker::after {
  top: auto;
  bottom: -1;
  background: linear-gradient(hsl(200 20% 10% / 0%), 35%, #ffffff);
}

.picker > * {
  display: grid;
  max-block-size: calc(7vmin * 5);
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  font-size: 5vmin;
  color: hsl(0 0% 20%);
}

.picker ul {
  margin-right: 1em;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  padding-bottom: 3em;
  padding-top: 3em;
}

.picker li {
  scroll-snap-align: center;
  height: 1.4em;
  text-align: right;
  word-spacing: 0.2em;
}

.picker-day {
  padding-right: 0.5em;
}

.picker-day span {
  padding-left: 0.56em;
}

.flex-fav {
  display: flex;
  justify-content: space-between;
}

.input-image-field {
  position: relative;
  width: 43px;
  opacity: 0;
  left: 50%;
  bottom: 49px;
  cursor: pointer;
  height: 32px;
}

.for-icon {
  position: relative;
  top: 36px;
  left: 10px;
}

.card-bg {
  top: 1px;
  position: absolute;
  background: #00000085;
  width: 100%;
  height: 100%;
}

.myStars span {
  display: -webkit-inline-box;
}

.top-Category-Card {
  border-radius: 20px;
  opacity: 0.4;
  width: 140px;
  height: 140px;
}

.alertButtonCss .alert-button.sc-ion-alert-ios:last-child {
  color: #c70404 !important;
}

/* ---------------- For Crousel------------ */
/* Slideshow */

/* .slideshow {
  margin: 0 auto;
  overflow: hidden;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  width: 100%;
  border-radius: 40px;
}

.slide img {
  border-radius: 20px;
  width: inherit;
  padding: 10px;
} */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  width: 100%;
  border-radius: 40px;
}

.slide img {
  width: 100%;
  border-radius: 20px;
  width: inherit;
  padding: 10px;
}

@media (max-width: 600px) {
  .slideshow {
    width: 370px;
    min-width: 100%;
  }
}

@media (max-width: 400px) {
  .slideshow {
    width: min(300px, 100%) !important;
  }
}

/* Buttons */

.slideshowDots {
  text-align: right;
  position: relative;
  bottom: 40px;
  right: 10px;
}

.slideshowDot {
  display: inline-block;
  height: 5px;
  width: 15px;
  border-radius: 30%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #22a45d;
}

ion-datetime-button::part(native) {
  background: #fff0;
  /* border: 1px solid #e1e1e1;
  border-radius: 8px; */
  width: 100%;
  color: #919191;
  text-align: left;
  height: 41px;
}

.ion-select-for-schedule {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  margin: 8px 0px;
  padding: 8px 20px;
  width: 100%;
  color: #919191;
}

.time-input-custom {
  background: white;
  border: 1px solid #e1e1e1;
  color: #919191;
  height: 41px;
}

.text-below-image {
  animation-name: text-right-to-left;
  animation-duration: 1.5s;
}

@keyframes text-right-to-left {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.logo-animate {
  animation: logo-zoom-in-to-out 1.5s ease;
}

@keyframes logo-zoom-in-to-out {
  0% {
    transform: scale(0.5, 0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(0.8, 0.8);
    opacity: 1;
  }
}

.orderActivated {
  box-shadow: 0px 0px 3px 2px rgba(194, 255, 194, 0.8);
  background-color: rgba(92, 184, 92, 1) !important;
}

.orderDeActivated {
  box-shadow: 0px 0px 3px 2px #ffa19b8a;
  background-color: rgb(215 1 1) !important;
}

.cuisiens-parent .swiper-slide {
  text-align: left;
  width: unset !important;
}

.cuisiens-parent .swiper-button-disabled {
  display: none;
}

.cuisiens-parent .swiper-button-prev::after {
  font-size: 20px !important;
  font-weight: 900;
  margin-left: -35px;
  color: #000000;
}

.cuisiens-parent .swiper-button-next::after {
  font-size: 20px !important;
  font-weight: 900;
  margin-right: -35px;
  color: #000000;
}

.cuisiens-parent .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: none;
}

.cuisiens-parent .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: none;
}

.restaurant-swiper-cards .swiper-slide,
.faves-swiper-cards .swiper-slide {
  width: unset !important;
}

.logo-strip {
  width: 100%;
  background: #22a45d;
}

.dasboard-menus-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.drop-in {
  animation: drop-in 1s ease 200ms backwards;
}

.drop-out {
  animation: drop-out 1s ease 200ms backwards;
}

#text2 {
  display: none;
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }

  to {
    opacity: 1;
    transform: translate(0px);
  }
}

@keyframes drop-out {
  from {
    opacity: 1;
    transform: translate(0px);
  }

  to {
    opacity: 0;
    transform: translateY(-100px);
  }
}

.apply-filter-parent .apply-filter-button {
  margin: 0 auto;
  /* position: fixed; */
  bottom: 100px;
  width: 50%;
  left: 0;
  right: 0;
  /* box-shadow: 11px 11px 30px #0e4225, 11px 11px 30px #36ff95; */
}

.onboarding-parent .swiper-pagination-bullet-active {
  background-color: #22a45d;
}

.onboarding-parent .swiper-pagination-bullet {
  background: #22a45d !important;
}

.onboarding-parent .swiper-pagination {
  position: fixed !important;
  bottom: 90px !important;
}

.onboarding-button {
  position: sticky;
  bottom: 20px;
  z-index: 10;
}

#ion-react-wrapper {
  color: black;
}

.taskbar:hover {
  color: #000000;
}

.tabsbar:hover {
  --color-selected: white;
}

.taskbarButton {
  --background: #e0e0e0;
  color: #000000;
}

.taskbarButton:hover {
  --color-selected: #000000;
}

ion-tab-button {
  border-radius: 10px;
}

.dynamic-tabs__box {
  margin: 0 -15px 20px -20px;
}

.dynamic-tabs__box:after {
  content: "";
  margin: -4px 20px 0;
  display: block;
  border-bottom: 1px solid #dddddd;
}

.dynamic-tabs__tab {
  text-decoration: none;
  display: inline-block;
  padding: 13px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  background: transparent;
  color: rgba(0, 0, 0, 0.5);
  margin: 4px;
  cursor: pointer;
  margin-left: 14px;
}

.dynamic-tabs__tab:hover {
  color: #22a45d;
  background-color: #22a45d0d;
}

.dynamic-tabs__tab-active {
  color: #22a45d;
}

.dynamic-tabs__tab-active:after {
  content: "";
  display: block;
  padding-top: 16px;
  margin-bottom: -16px;
  border-bottom: 2px solid #22a45d;
}

.parent-elipse {
  width: 280px;
  overflow: hidden;
}

.child-elipse {
  white-space: nowrap;
  /* Adjust as needed */
}

.child-elipse:last-child {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.faves-swiper-cards .swiper {
  margin-left: 0% !important;
}

.rmdp-panel-body li {
  height: 45px;
}

.onboarding h1 {
  margin-top: 0.5em;
  font-size: 18px;
  font-weight: 500;
}

.onboarding p {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.time-selector .ant-select-selector,
.ant-select-selection-search,
.ant-select-arrow,
.ant-select-clear,
.ant-select-selection-item-remove,
.ant-select-selection-search-input,
.ant-select-selection-placeholder,
.ant-select-selection-item {
  color: #919191 !important;
}

/*.anticon*/
.time-selector .ant-select-selection-item-content,
.ant-select-selection-item-remove,
.anticon-close {
  color: #d4d1d1;
}

.multiple-time-selector .ant-select-selection-item {
  background-color: #949494c3 !important;
}

.calendar-color,
.calendar-day {
  color: #ffffff !important;
}

.time-picker-color,
.ant-picker .ant-picker-input {
  color: #e1e1e1 !important;
  background: none !important;
}

.time-picker-color .ant-picker-input > input,
.ant-picker-suffix {
  color: #e1e1e1 !important;
}

.time-picker-color .ant-picker-input > input::placeholder {
  color: #919191 !important;
}

.anticon svg {
  display: block !important;
}

.PhoneInput {
  border: 1px solid silver;
  border-radius: 8px;
  padding: 0px 10px;
}

.PhoneInputCountrySelect {
  background: none;
  font-size: 0.75rem;
}

.country-selector .PhoneInputCountrySelect {
  background: #121212 !important;
}

.PhoneInputInput {
  background: none;
  border-left: 1px solid silver;
  padding: 5px;
  color: gray;
  outline: none;
  border-radius: 1px;
}

.popup-time-panel .ant-picker-panel-container {
  padding: 10px;
  text-align-last: center;
}

.popup-time-panel .ant-picker-panel-container button {
  color: green;
}

.popup-time-panel .ant-picker-panel-container .ant-picker-ranges {
  padding: 10px;
}

.popup-time-panel .ant-picker-panel-container .ant-picker-time-panel-cell-inner {
  padding-inline-start: unset !important;
  margin: 11px 0px !important;
}

/* ************************* Stepper CSS ****************************************** */

.stepper ul {
  display: flex;
  justify-content: center;
}

.stepper ul li {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Progress Div Css  */

.stepper ul li .progress {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgba(201, 201, 201, 1);
  margin: 14px 175px;
  display: grid;
  place-items: center;
  color: #fff;
  position: relative;
  cursor: pointer;
  min-width: 18px;
}

/* 
.customclass {
  text-align: center; */
/* } */

.stepper .progress::after {
  content: " ";
  position: absolute;
  width: 350px;
  height: 3px;
  background-color: rgba(201, 201, 201, 1);
  right: 18px;
}

.stepper .one::after {
  width: 0;
  height: 0;
}

/* Active Css  */
.stepper ul li .active {
  /* box-shadow: 0px 0px 17px 3px rgba(194, 255, 194, 0.8); */
  background-color: rgba(92, 184, 92, 1) !important;
  display: grid;
  place-items: center;
}

.stepper li .active::after {
  /* box-shadow: 0px 0px 17px 3px rgba(194, 255, 194, 0.8); */
  background-color: rgba(92, 184, 92, 1) !important;
}

.stepper ul li .de-active {
  /* box-shadow: 0px 0px 3px 2px #ffa19b8a; */
  background-color: rgb(215 1 1) !important;
  display: grid;
  place-items: center;
}

.stepper li .de-active::after {
  /* box-shadow: 0px 0px 3px 2px #ffa19b8a; */
  background-color: rgb(215 1 1) !important;
}

.one::after {
  width: 0;
  height: 0;
}

/* Responsive Css  */
@media (max-width: 980px) {
  .stepper ul {
    flex-direction: column;
  }

  .stepper ul li {
    flex-direction: row;
  }

  .stepper ul li .progress {
    margin: 45px 25px;
  }

  .stepper .progress::after {
    width: 3px;
    height: 110px;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    background-color: rgba(201, 201, 201, 1);
  }

  .stepper .one::after {
    height: 0;
  }

  .stepper ul li {
    align-items: baseline;
  }

  .stepper ul li:nth-child(2) {
    align-items: center;
  }

  .stepper ul li:last-child {
    align-items: flex-start;
  }
}

@media (max-width: 431px) {
  .wraptext {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.1;
  }
}

@media (max-width: 350px) {
  .wraptext {
    max-width: 120px;
  }
}

.google-login-button {
  font-size: 15px !important;
  font-weight: 500 !important;
  border-radius: 0.5rem !important;
  height: 39px !important;
  border: 1px solid #fff !important;
  align-items: center !important;
}

.apple-button:hover {
  background-color: #ffffff !important;
}

/* ****************************** Pull to refresh CSS************************************************** */

.ptr-element {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  color: #aaa;
  z-index: 10;
  text-align: center;
  height: 50px;
  transition: all;
}

.ptr-element .genericon {
  opacity: 0.6;
  font-size: 34px;
  width: auto;
  height: auto;
  transition: all 0.25s ease;
  transform: rotate(90deg);
  margin-top: 5px;
}

.ptr-refresh .ptr-element .genericon {
  transform: rotate(270deg);
}

.ptr-loading .ptr-element .genericon,
.ptr-reset .ptr-element .genericon {
  display: none;
}

.loading {
  display: inline-block;
  text-align: center;
  opacity: 0.4;
  margin: 12px 0 0 5px;
  display: none;
}

.ptr-loading .loading {
  display: block;
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  transform: scale(0.3);
  border-radius: 50%;
  animation: ptr-loading 0.4s infinite alternate;
}

.loading-ptr-1 {
  animation-delay: 0 !important;
}

.loading-ptr-2 {
  animation-delay: 0.2s !important;
}

.loading-ptr-3 {
  animation-delay: 0.4s !important;
}

@keyframes ptr-loading {
  0% {
    transform: translateY(0) scale(0.3);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    background-color: #b6b3b3;
    opacity: 1;
  }
}

.ptr-loading .refresh-view,
.ptr-reset .refresh-view,
.ptr-loading .ptr-element,
.ptr-reset .ptr-element {
  transition: all 0.25s ease;
}

.ptr-reset .refresh-view {
  transform: translate3d(0, 0, 0);
}

.ptr-loading .refresh-view {
  transform: translate3d(0, 30px, 0);
}

.facebook-login-button {
  font-size: 15px !important;
  font-weight: 500 !important;
  border-radius: 0.5rem !important;
  height: 39px !important;
  align-items: center !important;
}

.ion-safe-top {
  margin-top: var(--ion-safe-area-top, 0);
}

.home-page-content #background-content {
  background-color: #f4f3f3 !important;
}

/* -------------------------------------home tab start======================== */

.HomeTab_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.HomeTab_box .box {
  display: flex;
  gap: 5px;
  min-width: 50%;
}

.HomeTab_box .box button {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 50px;
  margin: 0 0.5px;
  font-weight: bold;
  color: rgb(158, 158, 157);
  display: flex;
  justify-content: center;
  align-items: center;
}

.HomeTab_box .box .active {
  background-color: #22a45d;
  color: white;
}

.HomeTab_box .box .active-init {
  color: #22a45d;
  background-color: white;
}

.HomeTab_box .box .active-border {
  color: #22a45d;
  background-color: #f3f4f7;
  /* border: 3px solid #22a45d; */
}

.HomeTab_box .box button span:nth-child(1) {
  border: 1px solid rgb(216, 215, 215);
  border-radius: 100px 100px;
  padding: 2px 5px;
  font-size: 14px;
  margin-right: 10px;
}

.HomeTab_box .box .btn1 {
  border-radius: 3px 0 0 3px;
  clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%);
  shape-outside: polygon(0% 0%, 89% 0, 100% 50%, 91% 100%, 0% 100%);
  margin-right: -13px;
}

.HomeTab_box .box .btn2 {
  clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 51%, 0% 0%);
  shape-outside: polygon(91% 0, 100% 50%, 92% 100%, 0% 100%, 8% 51%, 0% 0%);
  margin-left: -13px;
  margin-right: -13px;
}

.HomeTab_box .box .btn3 {
  border-radius: 0 3px 3px 0;
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 10% 51%, 0% 0%);
  shape-outside: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 7% 51%, 0% 0%);
  margin-left: -13px;
}

/* ---------------------------------home tag end----------------------------------------- */

/* ---------------------------------home list start----------------------------------- */

.list-home {
  display: flex;
  justify-content: center;
}

.getFood_box {
  /* opacity: 0.3; */
  /* border: 1px solid rgb(182, 182, 183); */
  /* width: 350px;
  height: 180px; */
  padding: 5px 8px 5px 8px;
  border-radius: 10px;
  box-shadow: 0px 0px 20.96px 0px #00000014;
  margin-top: 20px;
  margin-bottom: 20px;
}

.getfoodNow {
  background: linear-gradient(180deg, #ffffff 0%, #fff3e5 100%);
}

.food {
  background: linear-gradient(180deg, #ffffff 0%, #e0ffee 100%);
}

.getFood_box .Food_box_icon {
  display: flex;
  justify-content: space-between;
}

/* .getFood_box .Food_box_text {
  display: block;
} */

.getFood_box .Food_box_text .text {
  font-weight: bold;
  font-size: 17px;
  width: 100%;
  color: rgb(56, 56, 56);
}

.getFood_box .Food_order-orange {
  font-size: 11px;
  padding: 4px;
  background-color: #d97706;
  color: white;
  border-radius: 6px;
  height: 24px;
  min-width: 20px;
  text-align: center;
}

.getFood_box .Food_order-green {
  font-size: 11px;
  padding: 4px;
  background-color: #22a45d;
  color: white;
  border-radius: 6px;
  height: 24px;
  min-width: 20px;
  text-align: center;
}

.getFood_box .Food_box_text p {
  color: rgb(98, 98, 98);
  font-size: 13px;
}

.icon-link:hover svg path,
.icon-link:hover {
  fill: #22a45d;
  color: #22a45d;
}

.container-main {
  width: 100%;
}

.flex-container-main {
  display: flex;
}

.text-main {
  white-space: nowrap;
}

.custom-option {
  color: #9ca3af !important;
}

/* Hover effect for the option elements */
.custom-option:hover {
  background-color: #e2e2e2 !important;
}

/* --------------------------food box------------------home------------------------------ */
